angular.module('zwp', [])
.factory('$values', function() {
    var zwpValues = {"currency":{"978":"Euro","756":"Franken"},"country":{"40":"\u00d6sterreich","276":"Deutschland","756":"Schweiz"},"salutation":{"1":"Firma","2":"Herr","3":"Frau"},"type":{"1":"Wohnung","2":"Haus","3":"Wohngemeinschaft"},"pets":{"1":"Ja","2":"Nein","3":"Nach Vereinbarung"},"garage":["Keine","Garage","Au\u00dfenparkplatz","Carport","Tiefgarage","Keine"],"heating":{"0":"Keine Angabe","2":"Gas","3":"\u00d6l","4":"Elektro","5":"Fernw\u00e4rme","6":"Erdw\u00e4rme","7":"Pellet","8":"Sonstige"},"smoking":{"1":"Keine Einschr\u00e4nkung","0":"Nur an Nichtraucher"},"energypass_type":{"1":"Verbrauchsausweis","2":"Bedarfsausweis"}};
    var zwpCurrencySymbols = {"978":"\u20ac","756":"Fr"};
    var zwpVatRates = {"40":20,"276":19,"756":0}
    var zwpValueLists = {};

    angular.forEach(zwpValues, function(values,type) {
        zwpValueLists[type] = [];

        angular.forEach(values, function(value,id) {
            zwpValueLists[type].push({'id': parseInt(id), 'value': value});
        });
    });

    return {
        getValue: function(type, value) {
            return zwpValues[type][value];
        },
        getValueList: function(type) {
            return zwpValueLists[type];
        },
        getCurrencySymbol: function(currencyId) {
            return zwpCurrencySymbols[currencyId];
        },
        getVatRate: function(countryId) {
            return zwpVatRates[countryId];
        }
    };
})
.factory('$attributes', function() {
    var zwpAttributes = [{"id":1,"group":1,"icon":"speaker","title":"Stereoanlage"},{"id":2,"group":1,"icon":"wifi","title":"Internet"},{"id":3,"group":1,"icon":"phone","title":"Telefon"},{"id":4,"group":1,"icon":"tv","title":"Fernseher"},{"id":5,"group":1,"icon":"washingmachine","title":"Waschmaschine"},{"id":6,"group":1,"icon":"dryer","title":"Trockner"},{"id":7,"group":1,"icon":"dishwasher","title":"Sp\u00fclmaschine"},{"id":8,"group":1,"icon":"balcony","title":"Balkon"},{"id":9,"group":1,"icon":"terrace","title":"Terrasse"},{"id":10,"group":1,"icon":"elevator","title":"Aufzug"},{"id":11,"group":1,"icon":"kids","title":"Kindgerecht"},{"id":12,"group":1,"icon":"handicapped","title":"Behindertengerecht"},{"id":13,"group":1,"icon":"cleaning","title":"Reinigungsservice"},{"id":14,"group":1,"icon":"cabinet","title":"M\u00f6bliert"},{"id":15,"group":1,"icon":"smoking","title":"Raucher"}];
    var zwpAttributeLists = [];

    angular.forEach(zwpAttributes, function(attribute, id) {
        zwpAttributeLists.push({'id': parseInt(attribute.id), 'value': attribute.title});
    });

    zwpAttributeLists.sort(function(a, b) {
        return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1;
    });

    return {
        getAttributeList: function(group) {
            return zwpAttributeLists;
        }
    };
});